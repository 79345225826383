import React from 'react'
import Styles from "./IncubationCenter.module.css";
import image from "../../assets/img/Group 1321313995.png"

const IncubationCenter = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.bannerWrapper}>
      <div className={Styles?.LeftCont}>
        <h1>Incubation</h1>
        <p className={'paddingTop-3'}>
        At Business Optima, our <b>Incubation services</b>are meticulously designed to nurture businesses at various stages of their growth journey. Whether you're a budding startup, an established SME, or a large enterprise, our tailored incubation programs provide the strategic support, resources, and expertise needed to transform your ideas into successful, scalable ventures. 
        </p>
        <p className={'paddingTop-3'}>
        Our <b>Startup Incubation</b> helps businesses create <b>Minimum Viable Products (MVPs) and Minimum Sellable Products (MSPs)</b> to accelerate market entry, while <b>Small and Medium Enterprises (SMEs)</b> benefit from refining products and scaling operations. For <b>Enterprises, Platform Innovation</b> drives digital transformation with custom software development, data analytics, and architecture integration, ensuring sustained growth in competitive industries.
        </p>
        <p className={'paddingTop-3'}>
        Our holistic approach ensures that every aspect of your business is optimized for sustained growth and innovation.
        </p>
       
      
      </div>
      <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
      
    </div>

  
  </div>
  )
}

export default IncubationCenter