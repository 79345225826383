import LogoImage from "../assets/img/bo_logo.png";
import placeholder from "../../src/assets/img/user-image-table.png";
import image1 from "../assets/img/image1.jpg"
import image2 from "../assets/img/image2.jpg"
import image3 from "../assets/img/image3.png"
import image4 from "../assets/img/image4.png"
import image5 from "../assets/img/image5.png"
import image6 from "../assets/img/image6.png"
import image7 from "../assets/img/image7.jpeg"
import image8 from "../assets/img/image8.jpeg"
import image9 from "../assets/img/image9.png"
import image10 from "../assets/img/image10.png"
import image11 from "../assets/img/image11.png"
import image12 from "../assets/img/image12.jpeg"
import image13 from "../assets/img/image13.jpg"
import image14 from "../assets/img/image14.jpeg"
import image15 from "../assets/img/image15.png"
import image16 from "../assets/img/image16.jpg"
import image17 from "../assets/img/image17.jpg"
import image18 from "../assets/img/image18.jpeg"
import image19 from "../assets/img/image19.jpg"
import image20 from "../assets/img/image20.png"
import image21 from "../assets/img/image21.png"
import image22 from "../assets/img/image22.png"
import image23 from "../assets/img/image23.png"
import image24 from "../assets/img/image24.gif"
import image25 from "../assets/img/image25.jpeg"
import image26 from "../assets/img/image26.jpg"
// import image27 from "../assets/img/image27.jpg"
import image28 from "../assets/img/image28.gif"
import image29 from "../assets/img/image29.jpg"
import image30 from "../assets/img/image30.jpg"
import image31 from "../assets/img/image31.jpg"
import image32 from "../assets/img/image32.jpg"
import image33 from "../assets/img/image33.jpeg"
import image34 from "../assets/img/image34.jpeg"
import image35 from "../assets/img/image35.jpeg"
import image36 from "../assets/img/image36.jpeg"
import image37 from "../assets/img/image37.jpeg"
import image38 from "../assets/img/image38.jpeg"
import image39 from "../assets/img/image39.jpeg"
import image40 from "../assets/img/image40.jpeg"
import image41 from "../assets/img/image41.jpeg"
import image42 from "../assets/img/image42.jpeg"
import image43 from "../assets/img/image43.jpeg"
import image44 from "../assets/img/image44.jpeg"
import image45 from "../assets/img/image45.jpeg"
import image46 from "../assets/img/image46.jpeg"
import image47 from "../assets/img/image47.jpeg"
import image48 from "../assets/img/Frame 1321314009.png"
import image49 from "../assets/img/Frame 1321314010.png"
import image50 from "../assets/img/Frame 1321314011.png"
import image51 from "../assets/img/Frame 1321314012.png"
import image52 from "../assets/img/Frame 1321314013.png"
import image53 from "../assets/img/Group 1321313957 (1).png"
import image54 from "../assets/img/Group 1321313958.png"
import image55 from "../assets/img/Group 1321313959.png"
import image56 from "../assets/img/Group 1321313963.png"
import image57 from "../assets/img/Group 1321313964.png"
import image58 from "../assets/img/Group 1321313968.png"
import image59 from "../assets/img/Group 1321313971.png"
import image60 from "../assets/img/new.png"
import image61 from "../assets/img/Group 1321313977.png"
import image62 from "../assets/img/Group 1321313978.png"
import image63 from "../assets/img/Group 1321313979.png"
import image64 from "../assets/img/Group 1321313980.png"
import image65 from "../assets/img/Group 1321313984.png"
import image66 from "../assets/img/Group 1321313985.png"
import image67 from "../assets/img/Group 1321313986.png"
import image68 from "../assets/img/Group 1321313987.png"
import image69 from "../assets/img/Group 1321313988.png"
import image70 from "../assets/img/Group 1321313989.png"



export const placeHolderImage = placeholder;

export const Images1 = [
  {
    link: image1
  },
  {
    link: image2
  },
  {
    link: image3
  },
  {
    link: image4
  },
  {
    link: image5
  },
  {
    link: image6
  },
  {
    link: image7
  },
  {
    link: image8
  },
  {
    link: image9
  },
  {
    link: image10
  },
  {
    link: image36
  },
  {
    link: image37
  },
  {
    link: image38
  },
  {
    link: image39
  },
  {
    link: image40
  },
  {
    link: image41
  },
]
export const Images2 = [
  {
    link: image11
  },
  {
    link: image12
  },
  {
    link: image13
  },
  {
    link: image14
  },
  {
    link: image15
  },
  {
    link: image16
  },
  {
    link: image17
  },
  {
    link: image18
  },
  {
    link: image19
  },
  {
    link: image20
  },
  {
    link: image42
  },
  {
    link: image43
  },
  {
    link: image44
  },
  {
    link: image45
  },
  {
    link: image46
  },
  {
    link: image47
  },
]
export const Images3 = [
  {
    link: image21
  },
  {
    link: image22
  },
  {
    link: image23
  },
  {
    link: image24
  },
  {
    link: image25
  },
  {
    link: image26
  },
  // {
  //   link: image27
  // },
  {
    link: image28
  },
  {
    link: image29
  },
  {
    link: image30
  },
  {
    link: image31
  },
  {
    link: image32
  },
  {
    link: image33
  },
  {
    link: image34
  },
  {
    link: image35
  },
]
export const CLOUDDATA = [
  {
    icon: image61,
    title: "Improved productivity",
    description:
      "Administrators and operators no longer must perform manual configuration steps for data center infrastructure changes",
  },
  {
    icon: image62,
    title: "Improved reliability",
    description:
      "The configuration of the infrastructure is contained in electronic files, and software is responsible for initiating infrastructure changes, so there is less chance of human error",
  },
  {
    icon: image63,
    title: "Prevents configuration drift",
    description:
      "Terraform, being provisioning software, binds you to make changes in your container and only then deploy the new ones across every server. This separates server configuration from any dependency, resulting in identical instances across our infrastructures",
  },
  {
    icon: image64,
    title: "Easy collaboration",
    description:
      "Terraform, being provisioning software, binds you to make changes in your container and only then deploy the new ones across every server. This separates server configuration from any dependency, resulting in identical instances across our infrastructures",
  },
];

export const TECHNICALCARDS = [
  {
    icon: image59,
    title: "Reduce costs and enable application scalability",
    description:
      "Accelerated enterprise cloud services provide rapid discovery and prioritized disposition of application and data assets.",
  },
  {
    icon: image60,
    title: "Identify areas of change in foundational processes",
    description:
      "Operating model recalibration helps identify core processes for continuance, idle and change stemming from remote work",
  },
  {
    icon: image58,
    title: "Connect business goals to IT architecture",
    description:
      "Our capabilities can address your specific needs, such as support with cloud environments and cloud security to facilitate your journey to cloud.",
  },
];

export const APP_CURRENCY = "$";
export const APP_CURRENCY_CODE = "USD";

export const CARDS = [
  {
    icon: image65,
    title: "Cloud Migration",
  },
  { icon: image66, title: "Digital Transformation" },
  { icon: image67, title: "Reliability" },
  {
    icon: image68,
    title: "Cost Engineering",
  },
  { icon: image69, title: "DevOps" },
  { icon: image70, title: "SysOps" },
];
export const SIXPACKOFCLOUD = [
  {
    icon: <i class="fas fa-arrow-right-arrow-left"></i>,
    title: "Virtualization & Isolation",
  },
  { icon: <i class="fas fa-fingerprint"></i>, title: "OnDemand & Planet Scale" },
  { icon: <i class="fas fa-database"></i>, title: "Elasticity & Scalability" },
  {
    icon: <i class="fas fa-hand-holding-dollar"></i>,
    title: "High Availability & Resiliency",
  },
  { icon: <i class="fab fa-dev"></i>, title: "Pay per use Cloud Economics (CAPEX to OPEX)" },
  { icon: <i class="fas fa-code"></i>, title: "Cost Saving with Cloud Infrastructure" },
];
export const SIXPACKOFAI = [
  {
    icon: <i class="fas fa-arrow-right-arrow-left"></i>,
    title: "AI Prediction(Time, Behaviour)",
  },
  { icon: <i class="fas fa-fingerprint"></i>, title: "Generative AI" },
  { icon: <i class="fas fa-database"></i>, title: "Model monitoring and retraining (Data Quality)" },
  {
    icon: <i class="fas fa-hand-holding-dollar"></i>,
    title: "Data Pipeline & ETL",
  },
  { icon: <i class="fab fa-dev"></i>, title: "Recommendation System" },
  { icon: <i class="fas fa-code"></i>, title: "AI Pipeline & MLOPS" },
];
export const DEPARTMENTCARDS = [
  {
    icon: image53,
    title: "Business Consulting",
  },
  { icon: image54, title: "Technology Consulting" },
  { icon: image55, title: "Platform Consulting" },
  {
    icon: image56,
    title: "Financial Consulting",
  },
  { icon: image57, title: "Training & Talent Transformation" },
];
export const DEPARTMENTIMAGES = [
  {
    src: image48,
    title: "Business Consulting",
  },
  { src: image52, title: "Technology Consulting" },
  { src: image50, title: "Platform Consulting" },
  {
    src: image51,
    title: "Financial Consulting",
  },
  // { src: image52, title: "Training & Talent Transformation" },
];

export const APP_LOGO = LogoImage;

export const MENU_LIST = [
  {
    name: "Business Ops",
    link: "/business-ops",
    subMenuList: [
      {
        name: "Revenue Growth",
        link: "/revenue-growth",
      },
      {
        name: "Sales Automation",
        link: "/sales-automation",
      },
      {
        name: "Business Transformation",
        link: "/business-Transformation",
      },
      {
        name: "Profitability",
        link: "/profitability",
      },
    ],
  },
  {
    name: "Incubation Ops",
    link: "/incubation-center ",
    subMenuList: [
      {
        name: "Startup Incubation(MVP & MSP)",
        link: "/incubation-center-startups",
      },
      {
        name: "Product Incubation (SMEs)",
        link: "/SMEs",
      },
      {
        name: "Platform Innovation (Enterprise)",
        link: "/enterprises",
      },
     ,
    ],
  },
  {
    name: "Fin Ops",
    link: "/fin-ops",
    subMenuList: [
      {
        name: "Venture Capital",
        link: "/venture-capital",
      },
      {
        name: "IPO",
        link: "/ipo",
      },
      {
        name: "Angel Investments",
        link: "/angel-investments",
      },
      {
        name: "M&A",
        link: "/m-a",
      },
      {
        name: "SPAC",
        link: "/spac",
      },
    ],
  },
  {
    name:'Design Ops',
    link: "/design-ops",
  },
  {
    name: "Platform Ops",
    link: "/platform-ops",
    subMenuList: [
      {
        name: "Tecdemy",
        link: "/tecdemy",
      },
      {
        name: "Trainingdemy",
        link: "/trainingdemy",
      },
      {
        name: "iMetapro",
        link: "/imetapro",
      },
      {
        name: "Data Software Lab",
        link: "/datasoftwarelab",
      },
      {
        name: "Outgen AI",
        link: "/outgenai",
      },
      {
        name: "Marcom USA",
        link: "/marcomusa",
      },
      {
        name: "Solv Software",
        link: "/solvsoftware",
      },
      {
        name: "Pulse Predict",
        link: "/pulsepredict",
      },
      {
        name: "MobileApp Factory",
        link: "/mobileapp",
      },
    ],
  },
  {
    name: "Tech Ops",
    link: "/tech-ops",
    subMenuList: [
      {
        name: "CloudOps",
        link: "/cloud-ops",
        subMenu: [],
      },
      {
        name: "DataOps",
        link: "/data-ops",
        subMenu: [],
      },
      {
        name: "DevOps",
        link: "/dev-ops",
        subMenu: [],
      },
      {
        name: "AI&ML Ops",
        link: "/ai-ml-ops",
        subMenu: [],
      },
      {
        name: "SRE",
        link: "/sre",
        subMenu: [],
      },
      {
        name: "Cost Optimization",
        link: "/cost-optimization",
        subMenu: [],
      },
    ],
  },
  {
    name: "Training Ops",
    link: "/training-ops",
    subMenuList: [
      {
        name: "Courses",
        link: "/courses",
      },
      {
        name: "Gen AI training",
        link: "/gen-ai-training",
      },
      {
        name: "Cloud Computing",
        link: "/cloud-computing",
      },
      {
        name: "CICD-Deployment",
        link: "/cicd-deployment",
      },
      {
        name: "Managed Services",
        link: "/managed-services",
      },
      {
        name: "Full Stack",
        link: "/full-stack",
      },
      {
        name: "Container & EKS",
        link: "/container-eks",
      },
      {
        name: "Cost Management",
        link: "/cost-management",
      },
      {
        name: "Sales & Marketing training",
        link: "/sales-marketing-training",
      },
      {
        name: "IP Protection",
        link: "/ip-protection",
      },
    ],
  },
  {
    name: "Team+ Lease",
    link: "/teamlease",
    subMenuList: [
      {
        name: "Design Team",
        link: "/design-team",
      },
      {
        name: "Content Team",
        link: "/content-team",
      },
      {
        name: "Fullstack Team",
        link: "/fullstack-team",
      },
      {
        name: "AI Team",
        link: "/ai-team",
      },
      {
        name: "DevOps Team",
        link: "/devops-team",
      },
      {
        name: "Sales Team",
        link: "/sales-team",
      },
      {
        name: "Marketing Team",
        link: "/marketing-team",
      },
    ],
  },
  {
    name: "My Account",
    // link: "/my-account",
    subMenuList: [
      {
        name: "Dashboard",
        link: "/",
      },
      {
        name: "My Courses",
        link: "/mycourses",
      },
    ],
  },
  // {
  //   name: "Learning Resources",
  //   link: "/learning-resources",
  //   subMenuList: [
  //     {
  //       name: "Courses",
  //       link: "/courses",
  //     },
  //     {
  //       name: "Learners",
  //       link: "/learners",
  //     },
  //   ],
  // },
  {
    name: "About Us",
    link: "/about-us",
    subMenuList: [],
  },
  // {
  //   name: "Contact",
  //   link: "/contact-us",
  //   subMenuList: [],
  // },
];
