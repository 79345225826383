import React from 'react'
import Styles from "./Enterprises.module.css";
import image from "../../../assets/img/Group 1321313995.png"

const Enterprises = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.bannerWrapper}>
      <div className={Styles?.LeftCont}>
        <h1>Platform Innovation (Enterprise)</h1>
        <p className={'paddingTop-3'}>
        In today’s rapidly evolving digital landscape, large enterprises must continuously innovate to maintain their competitive edge. Our <b>Platform Innovation</b> services are designed to help enterprises build and scale advanced platform solutions that drive business transformation and operational excellence.
        </p>
        <p className={'paddingTop-3'}>
        We provide <b>strategic consulting</b> to help enterprises navigate their digital transformation journeys, identifying opportunities for innovation and implementing cutting-edge technologies. Our team designs <b>scalable and robust platform architectures</b> that support complex business operations, ensuring flexibility, security, and seamless integration with existing systems.

        </p>
        <p className={'paddingTop-3'}>
        Additionally, we develop tailored <b>software solutions</b> that meet the specific needs of your enterprise, leveraging the latest technologies to enhance <b>functionality and user experience</b>. Utilizing <b>advanced data analytics</b>, we help enterprises harness the power of their data to gain actionable insights, optimize decision-making, and drive strategic initiatives.
        </p>
        <p className={'paddingTop-3'}>
        Successful platform innovation requires <b>effective change management</b>. We offer comprehensive <b>training programs</b> and support to ensure smooth adoption and maximize the benefits of new technologies. Our Platform Innovation services enable enterprises to leverage deep technological expertise and innovative methodologies, fostering an environment of continuous improvement and sustained competitive advantage.
        </p>
     
      
      </div>
      <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
      
    </div>
  </div>
  )
}

export default Enterprises