import Button from "../Elements/Button/Button";
import Styles from "./Homepage.module.css";
import Image1 from "../../assets/img/Learn-DevOps-in-90-Min.jpg";
import Image2 from "../../assets/img/logo-service.png";
import Image3 from "../../assets/img/cta-icons.svg";
import CardContainer from "./CardContainer/CardContainer";
import Products from "./ProductsandServices/Products";
import ConsultingForm from "../ConsultingForm/ConsultingForm";
import Technical from "./Technical/Technical";
import CloudPage from "./Cloudpage/CloudPage";
import Departments from "./Departments/Departments";
import SixPackAI from "./SixPackOfAI/SixPackAI";
import SixPackCloud from "./SixPacksOfCloud/SixPackCloud";
import Marquee from "../Elements/Marquee/Marquee";
import { Images1, Images2, Images3 } from "../../Config/Config";
import Rocket from "../../assets/img/All Tecdemy 6pack ai post (2) 1.png"
import aboutusimage from "../../assets/img/Group 1321314000 (1).png"
import { useRef } from "react";
const Homepage = () => {

  const consultingFormRef = useRef(null);

  const scrollToConsultingForm = () => {
    const offsetPosition = consultingFormRef.current.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: offsetPosition - 100,
      behavior: "smooth",
    });
  };


  return (
    <div className={Styles?.Container}>
      <div className={Styles?.TopContainer}>
        <div className={`${Styles?.TopContent} paddingTop-5`}>
          <div className={Styles?.TextCont}>
          <h1>The Key to Growth & Happiness Starts Here </h1>
          <p>Business Optima Is a Boutique consulting firm in Finance & Healthcare with Platform Solutions, and Accelerator Program. Training & Transformation in Tech.
          </p>
          <Button
              label="Let's Get Started"
              className={`marginTop-4`}
              onClick={scrollToConsultingForm}
              />
          {/* <img src={Rocket} className={Styles?.rocket}/> */}
          </div>
          {/* <div className={Styles?.TopLeft}>
            <h1>Business Optima</h1>
            <p>
            Business Optima is a Boutique Business Consulting Company with specialization in Financial Consulting, Technology Consulting, Solution Acceleration, Platform Consulting, Training & Transformation Services.  We manage client partnerships and project teams to drive transformations. Contact us at sales@businessoptima.com for all your business consulting needs, and booking@businessoptima.com for your training needs.
            </p>
            <p>
            Our experienced consultants bring strategic thinking, industry knowledge, and the latest technology to help your business needs. Our expertise is spread across the finance domain, technology domain. Our team has helped businesses in the US, India, and the GCC region thrive by leveraging the power of Cloud computing & Managed Services. Our product & platforms use AI & machine learning extensively.
            </p>
            
          </div>
          <div className={`${Styles?.Image}`}>
            <img src={Image1} />
          </div> */}
        </div>
        <div className={`${Styles?.Service} paddingTop-1`}>
          {/* <div className={`${Styles?.Image1}`}>
            <img src={Image2} />
          </div>
          <div className={`${Styles?.Serviceright}`}>
            <p>
            We are specialized in Solution Acceleration, Financial Consulting, Technology Consulting, Platform Consulting & Training and Talent Transformation Services.
            </p>
            <p>
            We build resilient technology platform solutions that are highly scalable for our clients. Whether you're a startup or an established company, or an organization that is in need of a turnaround, we have optimal solutions to fit each of those needs.
            </p>
            <p>
            Our value proposition combines business expertise, technology platform solutions, training and transformation services.
            </p>
            <p>
            Let us help you achieve your organizational needs
            </p>
            <div className={Styles?.ButtonCont}>
            <Button label={"Book Consultants"}></Button>
            <Button label={"Hire a Team"}></Button>
            <Button label={"Enroll into our Training Ops"}></Button>
            </div>
          </div> */}
        </div>
        <Departments />
        <SixPackAI />
        <div className={`${Styles?.ConsultingContainer} marginBottom-5 marginTop-5 `}>
          <div className={Styles?.ConsultingLeft}>
            <h1>About Us</h1>
            <p>
            Business Optima is a Boutique Business Consulting Company specializing in Financial Consulting, Technology Consulting, Solution Acceleration, Platform Consulting, Training & Transformation Services. We manage client partnerships and project teams to drive transformations. Contact us at sales@businessoptima.com for all your business consulting needs, and booking@businessoptima.com for your training needs.
            </p>
            <p>
            Our experienced consultants bring strategic thinking, industry knowledge, and the latest technology to help your business needs. Our expertise is spread across the finance and technology domains. Our team has helped businesses in the US, India, and the GCC region thrive by leveraging the power of Cloud computing & Managed Services. Our products & platforms use AI & machine learning extensively.
            </p>
            <div className={Styles?.ButtonCont} >
              <Button label={"Book Consultants"} className={`${Styles?.button} marginTop-4`} onClick={scrollToConsultingForm}></Button>
              <Button label={"Hire a team"} className={`${Styles?.button} marginTop-4`} onClick={scrollToConsultingForm}></Button>
              <Button label={"Enroll into our training ops"} className={`${Styles?.button} marginTop-4`} onClick={scrollToConsultingForm}></Button>
            </div>
          </div>
          <img src={aboutusimage} className={Styles?.AboutImage}></img>
        </div>
        <Technical /> 
        <div className={`${Styles?.ConsultingContainer} paddingTop-5 marginBottom-5 `} ref={consultingFormRef} >
          <div className={Styles?.ConsultingLeft}>
            <h1>Multi Cloud Strategy Consulting</h1>
            <p>
            Server infrastructure is vital for digital services in the modern enterprise. Every aspect of the business operations relies on the IT infrastructure to achieve business objectives. For this reason, our experts believe businesses must choose the optimal platform that best caters to individual workloads.
            </p>
            <p>
            It is projected that the global cloud services revenue will increase to $331.2 billion by 2022. Additionally, a leading research firm forecasts that by 2022, 26% of ISV application spending will be on SaaS and 1% of data center spending will be on IaaS.
            </p>
          </div>
          <div className={Styles?.ConsultingForm}>
          <ConsultingForm />
          </div>
        </div>
        
        {/* <div className={`${Styles.TransformContainer}`}>
          <img src={Image3} className={Styles?.Image3} />
          <div className={`${Styles?.Transformright}`}>
            <h1>Transform Your Business with Cloud Services</h1>
            <p>
              Are you ready to unlock the limitless potential of the cloud? Our
              cloud-based services are designed to supercharge your business,
              making it more efficient, scalable, and secure than ever before.
            </p>
            <Button
              label="Contact Us"
              className={`${Styles?.button} marginTop-4`}
            />
          </div>
        </div> */}
        
        {/* <SixPackCloud /> */}
        <CloudPage />
        <Products />
        <div className={`${Styles.ClientContainer}`}>
        <h1>Few Of Our Clients</h1>
        <Marquee images={Images1} />
        <Marquee images={Images2} direction={"right"}/>
        <Marquee images={Images3} />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
