import React from 'react'
import Styles from "./SME.module.css";
import image from "../../../assets/img/Group 1321313995.png"
const SME = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.bannerWrapper}>
      <div className={Styles?.LeftCont}>
        <h1>Product Incubation (SMEs)</h1>
        <p className={'paddingTop-3'}>
        For <b>Small and Medium Enterprises (SMEs)</b>, our <b>Product Incubation services</b> focus on refining and scaling products to enhance market competitiveness and drive sustainable growth. We recognize that SMEs often face unique challenges in product development, such as limited resources, market pressures, and the need for continuous innovation.

        </p>
        <p className={'paddingTop-3'}>
        We work closely with SMEs to develop comprehensive product strategies that align with business goals, market trends, and customer needs. This includes creating detailed road maps to guide <b>product evolution</b>. Our experts analyze and streamline your product development processes to improve efficiency, reduce time-to-market, and minimize costs without compromising quality.
        </p>
        <p className={'paddingTop-3'}>
        Conducting <b>in-depth market research</b> is crucial; we identify emerging trends, customer preferences, and competitive landscapes, enabling SMEs to make informed decisions and stay ahead of the curve. <b>Ensuring product reliability and performance</b> is essential, so we provide robust quality assurance and testing services to maintain high standards and meet regulatory requirements.
        </p>
        <p className={'paddingTop-3'}>
        As your product gains traction, we assist in scaling operations, including manufacturing, supply chain management, and distribution, to <b>support increased demand and expansion</b>. Our Product Incubation services empower SMEs to innovate continuously, enhance their product offerings, and achieve significant market growth, positioning them as leaders in their respective industries..
        </p>
        
      
      </div>
      <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
      
    </div>
  </div>
  )
}

export default SME