import React from 'react'
import Styles from "./DesignOps.module.css";
import image from "../../../assets/img/Group 1321313995.png"
const DesignOps = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
      <div className={Styles?.bannerWrapper}>
        <div className={Styles?.LeftCont}>
          <h1>Design Ops</h1>
          <p className={'paddingTop-3'}>
           
          <b>  Design Operations (DesignOps) </b>focuses on optimizing and managing the design process across various platforms to drive efficiency, scalability, and alignment with business objectives. Key aspects of<b> DesignOps</b> include <b>workflow management, tool standardization, collaborative design systems,</b> and <b>continuous performance monitoring.</b>
          </p>
          <p className={'paddingTop-3'}>
          In traditional organizations, design, development, and marketing often operate in separate silos, creating inefficiencies and misalignment. <b>DesignOps</b> centralizes these processes, reducing fragmentation and promoting a unified approach to design. By establishing <b>standardized design workflows,</b> we ensure that design decisions support broader business goals, minimizing operational complexity and improving overall efficiency.
          </p>
          <p className={'paddingTop-3'}>
          A key component of <b>DesignOps</b> is the development of <b>scalable design systems.</b> These systems consist of reusable design components, guidelines, and patterns that ensure design consistency across all platforms and projects. This approach not only improves the speed and quality of design deliverables but also reduces repetitive work and operational costs. It ensures that the user experience remains seamless, cohesive, and aligned with the brand’s identity.
          </p>
          <p className={'paddingTop-3'}>
          <b>Collaboration</b> is at the heart of <b>DesignOps</b>, allowing design, development, and other teams to work seamlessly together. By integrating tools into project management systems, we enable real-time collaboration, ensuring that everyone is on the same page throughout the design process. This integrated workflow reduces version control issues and enables faster decision-making, resulting in quicker project completions.
          </p>
          <p className={'paddingTop-3'}>
          For specific platforms such as <b>Tecdemy, Trainingdemy, iMetapro, Outgen AI, and MobileApp Factory, DesignOps</b> manages unique design needs, including user interface (UI) optimization, user experience (UX) design and brand consistency. Whether it's optimizing the design for e-learning platforms or AI-driven applications, <b>DesignOps</b> ensures that the design aligns with business objectives, improves functionality and enhances the overall user experience.
          </p>
          <p className={'paddingTop-3'}>
          Effective management of these platforms includes <b>performance monitoring</b> and <b>data-driven improvements.</b> We continuously measure the performance of design decisions based on user feedback and key metrics, ensuring that designs remain effective, functional and user-friendly. By aligning design work with business goals, <b>DesignOps</b> supports overall operational efficiency and business growth.
          </p>
          <p className={'paddingTop-3'}>
          Ultimately, <b>DesignOps</b> at <b>Business Optima</b> provides innovative, scalable, and efficient design solutions that deliver high-quality outputs, improve resource utilization, and drive success across platforms. Through a unified and collaborative approach, we ensure that design serves as a key enabler of business performance and long-term growth.
          </p>
        </div>
        <div className={Styles?.rightCont}>
          <img src={image} />
        </div>
        
      </div>

    
    </div>
  )
}

export default DesignOps