import React from 'react'
import Styles from "./OutsourcedIncubation.module.css";
import image from "../../../assets/img/Group 1321313995.png"

const OutsourcedIncubation = () => {
    return (
        <div className={`${Styles?.Container} marginTop-5`}>
            <div className={Styles?.bannerWrapper}>
                <div className={Styles?.LeftCont}>
                    <h1>Outsourced Incubation</h1>
                    <p className={'paddingTop-3'}>
                        At Business Optima, we provide <b> Outsourced Incubation</b> services designed to help organizations leverage external expertise and resources for innovative growth. This model allows businesses to focus on their core operations while we manage the incubation process, delivering strategic support tailored to their unique needs.
                    </p>
                    <p className={'paddingTop-3'}>
                        Our <b>Outsourced Incubation</b> program encompasses everything from ideation and product development to market entry strategies and operational scaling. By collaborating with our team of experts, organizations gain access to industry insights, market research, and best practices that drive innovation and efficiency.
                    </p>
                    <p className={'paddingTop-3'}>
                        We offer <b> flexible engagement models</b>, allowing enterprises to tap into our incubation resources as needed. This approach enables businesses to accelerate their growth trajectory while minimizing risks associated with new ventures. Our network of advisors and industry connections further enhances the incubation experience, facilitating collaboration and partnership opportunities that can lead to successful outcomes.
                    </p>
                    <p className={'paddingTop-3'}>
                    With a focus on <b>fostering innovation</b> and<b> adaptability</b> , our Outsourced Incubation services empower organizations to navigate challenges, seize opportunities, and achieve sustainable growth in an ever-changing marketplace.
                    </p>
                 


                </div>
                <div className={Styles?.rightCont}>
                    <img src={image} />
                </div>

            </div>
        </div>
    )
}

export default OutsourcedIncubation