import React from 'react'
import Styles from "./Startups.module.css";
import image from "../../../assets/img/Group 1321313995.png"
const Startups = () => {
  return (
    <div className={`${Styles?.Container} marginTop-5`}>
    <div className={Styles?.bannerWrapper}>
      <div className={Styles?.LeftCont}>
        <h1>Startup Incubation (MVP & MSP)</h1>
        <p className={'paddingTop-3'}>
        Our <b>Startup Incubation</b> program is specifically designed to support early-stage businesses in developing their foundational products and services. We understand that <b>transforming a visionary idea into a tangible product</b> requires more than just passion; it demands strategic planning, expert guidance, and access to the right resources.
        </p>
        <p className={'paddingTop-3'}>
        We assist startups in creating <b>Minimum Viable Products (MVPs)</b> that effectively demonstrate their core functionalities and value propositions. This process includes product design, prototyping, and iterative testing to ensure market fit. Beyond the MVP, we help refine your product into a <b>Minimum Sellable Product (MSP)</b> to meet market demands  ensuring it is ready for commercialization. This involves enhancing features, improving user experience, and preparing for launch.
        </p>
        <p className={'paddingTop-3'}>
        Additionally, our seasoned mentors provide invaluable insights into business strategy, market positioning, and growth tactics, helping startups navigate the complexities of early-stage development. By participating in our <b>Startup Incubation program</b>, entrepreneurs gain the critical support needed to accelerate their journey from concept to launch, laying a strong foundation for future success.
        </p>
    
      
      </div>
      <div className={Styles?.rightCont}>
        <img src={image} />
      </div>
      
    </div>

  
  </div>
  )
}

export default Startups